<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-02-22 15:17:06
 * @LastEditTime: 2022-08-11 17:48:39
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/costManagement/editExpenseDetail.vue
-->

<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="expenseDetailDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    append-to-body
  >
    <el-form
      :model="params"
      ref="editExpenseDetail"
      :rules="rules"
      label-width="120px"
      class="addDailog"
    >
      <el-form-item label="日程" prop="refId">
        <el-select
          v-model="params.refId"
          @visible-change="getCalendarScheduleSelectList($event)"
          @change="calendarScheduleChange($event)"
          filterable
          placeholder="请选择日程"
          clearable
          :no-data-text="!calendarScheduleSelectList ? '加载中···' : '暂无数据'"
          :disabled="type == 'edit'"
        >
          <el-option
            v-for="item in calendarScheduleSelectList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
            {{ item.title }}
            <span class="workflowVersion">{{
              item.ownerId == userId ? "我的" : item.ownerName
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="行程" prop="title">
        <el-select
          v-model="params.title"
          @visible-change="getScheduleTriplist($event)"
          filterable
          placeholder="请选择"
          clearable
          :no-data-text="!scheduleTriplist ? '加载中···' : '暂无数据'"
        >
          <el-option
            v-for="(item, index) in scheduleTriplist"
            :key="index"
            :value="item.fromCity + '->' + item.toCity"
          >
            {{ item.fromCity }}->{{ item.toCity }}
            <span class="workflowVersion"
              >{{ item.startTime }} - {{ item.endTime }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="费用类型" prop="feeType">
        <el-select
          v-model="params.feeType"
          @visible-change="selectListDict($event, 'expense_detail_type')"
          filterable
          placeholder="请选择"
          clearable
          :no-data-text="!feeTypeList ? '加载中···' : '暂无数据'"
        >
          <el-option
            v-for="item in feeTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="费用名称" prop="feeTitle">
        <el-input type="text" v-model="params.feeTitle"></el-input>
      </el-form-item>

      <el-form-item label="费用发生时间" prop="feeStartDate">
        <el-date-picker
          v-model="params.feeStartDate"
          type="date"
          placeholder="选择日期"
          align="right"
          :picker-options="pickerOptions"
          style="width: 100% !important"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="申请金额" prop="feeAmount">
        <el-input
          type="number"
          v-model="params.feeAmount"
          placeholder="请输入申请金额"
          :min="0"
          :controls="false"
          :precision="2"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="费用关联人" prop="relatedUserIdList">
        <el-select
          v-model="params.relatedUserIdList"
          @visible-change="getScheduleRelatedUserList($event)"
          filterable
          multiple
          placeholder="请选择费用关联人"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in myProjectWorkflowMembers"
            :key="item.uid"
            :label="item.realName"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否替票" prop="isSubstituteTicket">
        <el-switch
          v-model="params.isSubstituteTicket"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="附件" prop="files">
        <!-- <el-button type="primary" size="mini" @click="addFile('file')"
          >+附件</el-button
        > -->
        <addFile
          :addFileiDalogVisible2="true"
          :relevantFile="fileTitle"
          :attachments="params.files"
          v-on:listenAddFileClose="listenAddFileClose"
          v-on:deleteFile="deleteFile"
        ></addFile>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>

    <!-- 上传文件 -->
    <!-- <addFile
      v-if="addFileiDalogVisible"
      :addFileiDalogVisible="addFileiDalogVisible"
      :relevantFile="fileTitle"
      :attachments="params.files" 
      v-on:listenAddFileClose="listenAddFileClose"
    ></addFile> -->
  </el-dialog>
</template>

<script>
import { CURRENCY_TYPE_LIST, INDUSTRY_LIST } from "@/common/constants";
import {
  addExpenseDetail,
  editExpenseDetail,
  getScheduleTriplist,
  getExpenseInfo,
  getScheduleRelatedUserList,
} from "@/api/costManagement";
import { selectListDict } from "@/api/dataManagement";
import { formatDate } from "@/common/date";
import { getCalendarScheduleSelectList } from "@/api/calendar";
import addFile from "@/components/addFile/addFile";
import { stringify } from "qs";

export default {
  name: "editExpenseDetail",
  props: {
    type: String,
    expenseDetailDialog: Boolean,
    id: Number,
    expenseDetailType: String,
    detail: Object,
    scheduleId: String,
  },
  data() {
    return {
      //1
      calendarScheduleSelectList: null,
      myProjectWorkflowMembers: null,
      scheduleTriplist: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      feeTypeList: [],
      params: {
        refType: "schedule",
        title: null,
      },
      addFileiDalogVisible: false, //上传文件
      fileTitle: "费用附件",
      title: null,
      rules: {
        title: [{ required: true, message: "请选择行程", trigger: "change" }],
        feeTitle: [
          { required: true, message: "请输入费用名称", trigger: "blur" },
        ],
        feeAmount: [
          { required: true, message: "请输入申请金额", trigger: "blur" },
        ],
        refId: [{ required: true, message: "请选择日程", trigger: "change" }],
        feeStartDate: [
          { required: true, message: "请选择行程", trigger: "change" },
        ],
        feeType: [
          { required: true, message: "请选择费用类型", trigger: "change" },
        ],
        files: [{ required: false, message: "请上传附件", trigger: "change" }],
      },
      //0

      restaurants: [],

      currencyTypeList: CURRENCY_TYPE_LIST, //货币类型(ISO4217)
      industryList: INDUSTRY_LIST, //投资领域
      remoteFullNameOptions: [],
      userId: window.localStorage.getItem("userId"),
    };
  },
  computed: {},
  components: {
    addFile,
  },
  watch: {
    scheduleId: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.params.refId = Number(newVal);
        this.getCalendarScheduleSelectList(true);
        this.getScheduleTriplist(true);
      },
      deep: true,
      immediate: true,
    },
    detail: {
      handler(newVal, oldVal) {
        if (!newVal) return;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.type == "edit") {
      this.title = "编辑";
      // this.getExpenseInfo(this.detail.id);
      this.params = JSON.parse(JSON.stringify(this.detail));
      this.params.files = JSON.parse(this.detail.attachments);
      this.params.refId = Number(this.params.refId);
      this.getScheduleRelatedUserList(true);
      this.getCalendarScheduleSelectList(true);
      this.getScheduleTriplist(true);
      this.selectListDict(true, "expense_detail_type");
       let detail = JSON.parse(JSON.stringify(this.detail));
      let relatedUserList = detail.relatedUserList;
      let relatedUserIdList = [];
      relatedUserList.map(function (item, index) {
        relatedUserIdList.push(item.userId);
      });
      this.params.relatedUserIdList = relatedUserIdList;
    } else if (this.type == "add") {
      this.title = "新增";
    } else if (this.type == "copy") {
      this.title = "新增";
      this.params = JSON.parse(JSON.stringify(this.detail));
      this.params.refId = Number(this.params.refId);
      this.getCalendarScheduleSelectList(true);
      this.getScheduleTriplist(true);
      this.selectListDict(true, "expense_detail_type");
      this.getScheduleRelatedUserList(true);
    }
  },
  mounted() {},
  methods: {
    addFile() {
      this.addFileiDalogVisible = true;
    },
    deleteFile(value) {
      console.log(value);
      if (!this.params.deleteFiles) {
        this.params.deleteFiles = [];
        this.params.deleteFiles.push(value);
      }
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      const { type, isClose, files } = params;
      this.addFileiDalogVisible = !isClose;
      if (type == "sure") {
        console.log(files);
        this.params.addFiles = files;
      }
    },
    calendarScheduleChange(event) {
      console.log(event);
      this.params.title = null;
      this.getScheduleTriplist(true);
    },
    getExpenseInfo(id) {
      getExpenseInfo(id).then((response) => {
        if (response.success) {
          let params = response.data;
          this.params = params;
        }
      });
    },
    async selectListDict(cb, type) {
      if (cb) {
        let info = { group: type };
        const list = await selectListDict(info);
        this.feeTypeList = list.data;
      }
    },
    getScheduleTriplist(cb) {
      if (cb) {
        if (!this.params.refId) {
          this.$message({ type: "warning", message: "请选择日程!" });
          return false;
        }
        getScheduleTriplist(this.params.refId).then((res) => {
          if (res.success) {
            this.scheduleTriplist = res.data;
            if (this.scheduleTriplist.length == 1) {
              this.params.title =
                this.scheduleTriplist[0].fromCity +
                "->" +
                this.scheduleTriplist[0].toCity;
              this.$forceUpdate();
            }
          }
        });
      }
    },
    //1
    getScheduleRelatedUserList(cb) {
      if (!cb) return;
      if (!this.params.refId) {
        this.$message({ type: "warning", message: "请选择日程!" });
        return false;
      }
      getScheduleRelatedUserList(this.params.refId).then((res) => {
        if (res.success) {
          let myProjectWorkflowMembers = res.data;
          // //去除自己
          // myProjectWorkflowMembers.splice(
          //   myProjectWorkflowMembers.findIndex((x) => x.uid === this.userId),
          //   1
          // );
          this.myProjectWorkflowMembers = myProjectWorkflowMembers;
        }
      });
    },
    //
    getCalendarScheduleSelectList(cb) {
      console.log(cb);
      if (cb) {
        let info = { mode: 1, officeModeList: ["3", "4"] };
        getCalendarScheduleSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;
            // list.map(function (item, index) {
            //   item.title =
            //     item.title +
            //     " " +
            //     formatDate(new Date(item.beginTime), "yyyy-MM-dd");
            // });
            this.calendarScheduleSelectList = list;
          }
        });
      }
    },
    addExpenseDetail() {
      let {
        refType,
        refId,
        title,
        feeType,
        feeStartDate,
        feeAmount,
        isSubstituteTicket,
        feeTitle,
        addFiles,
        relatedUserIdList,
      } = this.params;
      addExpenseDetail({
        refType,
        refId,
        title,
        feeType,
        feeStartDate,
        feeAmount,
        isSubstituteTicket,
        feeTitle,
        addFiles,
        relatedUserIdList,
      }).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "添加成功!" });
          this.params = {};
          this.$emit("listenExpenseDetailClose", {
            type: "sure",
            isClose: true,
            info: response.data,
          });
        }
      });
    },
    editExpenseDetail() {
      let {
        refType,
        id,
        refId,
        title,
        feeType,
        feeStartDate,
        feeAmount,
        isSubstituteTicket,
        feeTitle,
        addFiles,
        deleteFiles,
        relatedUserIdList,
      } = this.params;

      editExpenseDetail({
        refType,
        id,
        refId,
        title,
        feeType,
        feeStartDate,
        feeAmount,
        isSubstituteTicket,
        feeTitle,
        addFiles,
        deleteFiles,
        relatedUserIdList
      }).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.params = {};
          this.$emit("listenExpenseDetailClose", {
            type: "sure",
            isClose: true,
            info: response.data,
          });
        }
      });
    },
    //确定
    sure() {
      this.$refs["editExpenseDetail"].validate((valid) => {
        if (valid) {
          if (this.type == "add" || this.type == "copy") {
            this.addExpenseDetail();
          } else if (this.type == "edit") {
            this.editExpenseDetail();
          }
        } else {
          console.log("-------------error");
        }
      });
    },

    resetForm() {
      this.$refs["params"].resetFields();
    },
    //弹窗关闭
    dialogClose() {
      this.params = {};
      this.$emit("listenExpenseDetailClose", { type: "cancel", isClose: true });
    },
  },

  beforeCreate() {},
  beforeMount() {},

  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.addDailog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .el-form-item2 {
    width: 100% !important;
    height: auto;
    text-align: left !important;
  }
  .el-form-item {
    text-align: left;
    width: 48%;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left;
      .el-input__inner {
        text-align: left !important;
      }
    }
    .addContributionRatio {
      width: 20% !important;
      float: right;
      text-align: center;
      i {
        margin: 0 2px;
      }
    }
  }
}
</style>